.content-block-split-width.symmetrical-feature__product {
  display: grid !important;
  grid-template-rows: [image] auto [product-title] 0fr [product-description] 0fr [product-pricing] 0fr [product-cta] 0fr [footer] 0fr;
  &.mpp-feature-block {
    @include fluid-sizing(padding-#{$ldirection}, 18px, 20px, $medium-up, $landscape-up, 'width', false);
    @include fluid-sizing(padding-#{$rdirection}, 18px, 20px, $medium-up, $landscape-up, 'width', false);
    padding: 0;
    @include breakpoint($medium-only) {
      padding-bottom: 20px;
    }
  }
  &:hover {
    .column {
      &__1,
      &__2 {
        &.content-block-split-width__product--quickshop {
          opacity: 1;
          transition: all $transition-effect $transition-duration;
        }
      }
    }
  }
  .content-block__line {
    padding: 4px 0 0;
  }
  .column {
    &__1,
    &__2 {
      &.content-block-split-width {
        &__product--title,
        &__caption {
          padding-top: 16px;
        }
        &__product {
          &--title,
          &--description,
          &--pricing,
          &--cta,
          &--quickshop,
          &--atb {
            @include x-padding(20px);
            @include breakpoint($medium-up) {
              padding-#{$rdirection}: 17%;
              padding-#{$ldirection}: 0;
            }
          }
        }
        &__product--image {
          aspect-ratio: 4 / 5;
          position: relative;
          .mantle-media-asset {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          img {
            &.product-image {
              transition: all $transition-effect $transition-duration;
              &__base {
                opacity: 1;
              }
              &__hover {
                opacity: 0;
                position: absolute;
                top: 0;
              }
              &:hover {
                &.product-image {
                  transition: all $transition-effect $transition-duration;
                  &__base {
                    opacity: 0;
                  }
                  &__hover {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
    &__1 {
      @include breakpoint($medium-up) {
        flex-direction: row;
      }
      &.content-block-split-width {
        &__product {
          grid-row: 2;
          @include breakpoint($medium-up) {
            grid-row: 1;
          }
          &--title {
            grid-row: 3;
            @include breakpoint($medium-up) {
              grid-row: 2;
            }
          }
          &--description {
            grid-row: 4;
            @include breakpoint($medium-up) {
              padding-top: 0;
              padding-#{$rdirection}: 20px;
              grid-row: 3;
            }
          }
          &--pricing {
            grid-row: 5;
            @include breakpoint($medium-up) {
              grid-row: 4;
            }
          }
          &--cta {
            grid-row: 6;
            padding-top: 8px;
            @include breakpoint($medium-up) {
              grid-row: 5;
            }
          }
          &--quickshop {
            grid-row: 7;
            @include breakpoint($medium-up) {
              transition: all $transition-effect $transition-duration;
              opacity: 0;
              grid-row: 6;
            }
          }
          &--atb {
            grid-row: 8;
            @include breakpoint($medium-up) {
              grid-row: 7;
            }
          }
          &--image {
            position: relative;
            @include breakpoint($medium-up) {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
    &__2 {
      grid-row: 1;
      @include breakpoint($medium-up) {
        grid-row: 1;
      }
      &.content-block-split-width {
        &__hero-image {
          aspect-ratio: 4 / 5;
          img {
            object-fit: contain;
            width: 100%;
          }
        }
        &__caption {
          display: none;
          @include breakpoint($medium-up) {
            padding-#{$rdirection}: 40px;
            display: block;
            grid-row: 2/5;
          }
          .style--bare {
            border-bottom: none;
          }
        }
      }
    }
    &__all {
      grid-column: 1 / span 2;
      &.content-block-split-width {
        &__quickshop {
          grid-row: 13;
          @include breakpoint($medium-up) {
            grid-row: 11;
          }
          .product-quickshop {
            margin-top: 5px;
          }
        }
      }
    }
  }
  &.product-brief--show-shade-size {
    .content-block-split-width {
      &__product--size {
        grid-row: 8;
        @include breakpoint($medium-up) {
          grid-row: 5;
        }
      }
      &__product--title {
        grid-row: 9;
        @include breakpoint($medium-up) {
          grid-row: 6;
        }
      }
      &__product--description {
        grid-row: 10;
        @include breakpoint($medium-up) {
          grid-row: 7;
        }
      }
      &__product--pricing {
        grid-row: 11;
        @include breakpoint($medium-up) {
          grid-row: 8;
        }
      }
      &__product--atb {
        a.button,
        div.button {
          width: auto;
          &--disabled {
            border-bottom: none;
          }
        }
      }
    }
  }
}
