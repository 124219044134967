.content-block-split-width {
  .content-block {
    &__line {
      @include swap-direction(padding, 10px 20px 10px 0);
      @include breakpoint($med-small-up) {
        @include swap-direction(padding, 10px 28px 10px 0);
      }
      @include breakpoint($medium-up) {
        padding: 10px 0;
      }
    }
  }
}