.content-block-split-width {
  &--asymmetrical {
    display: grid;
    grid-gap: 0;
    column-gap: 0;
    grid-template-areas: "title . " "body . " "cta . " "image image" "footer .";
    grid-template-rows: [title] auto [body] auto [cta] auto [image] auto [footer] auto;
    grid-template-columns: [col1] 1fr;
    padding: 0 20px;
    @include breakpoint($medium-up) {
      grid-template-columns: [col1] 7fr [col2] 10fr;
      padding-#{$ldirection}: 40px;
      padding-#{$rdirection}: 0;
      column-gap: 175px;
    }
    &.column-layout--reversed {
      @include breakpoint($medium-up) {
        grid-template-columns: [col1] 10fr [col2] 7fr;
        padding-#{$ldirection}: 0;
        padding-#{$rdirection}: 40px;
      }
    }
  }
}