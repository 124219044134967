.content-block-split-width {
  &--symmetrical {
    display: grid;
    grid-gap: 0;
    grid-template-areas: 'title . ' 'body . ' 'image image' 'footer footer';
    grid-template-rows: [title] auto [body] auto [image] auto [footer] auto;
    grid-template-columns: [col1] 1fr;
    padding: 0;
    @include breakpoint($medium-up) {
      grid-template-columns: repeat(2, 1fr);
      padding: 0 40px;
    }
  }
}