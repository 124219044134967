.content-block-split-width {
  &__eyebrow {
    .dynamic-fill,
    .style--bare,
    .format--default {
      @include typestyle--b2;
      text-transform: uppercase;
      color: $color-neutral-gray;
    }
  }
  &__title {
    padding-top: 0;
    word-break: break-word;
    @include breakpoint($med-small-up) {
      word-break: keep-all;
    }
    .dynamic-fill,
    .style--bare,
    .format--default {
      @include typestyle--h3;
      line-height: normal;
      color: $color-neutral-dark-gray;
      text-transform: uppercase;
    }
  }
  &__subtitle {
    .dynamic-fill,
    .style--bare,
    .format--default {
      @include typestyle--h6;
      color: $color-neutral-dark-gray;
    }
  }
  &__body {
    max-width: 100%;
    @include breakpoint($medium-up) {
      max-width: 60%;
    }
    .dynamic-fill,
    .style--bare,
    .format--default {
      @include typestyle--b1;
      color: $color-neutral-gray;
    }
  }
  &__cta {
    .dynamic-fill,
    .style--bare,
    .format--default {
      @include link;
      @include typestyle--h8;
      color: $color-neutral-dark-gray;
      border-bottom: 0;
      padding-top: 10px;
    }
    a.link {
      @include typestyle--h8;
      color: $color-neutral-dark-gray;
      background: none;
      border: none;
      padding: 0;
      text-align: $ldirection;
      text-decoration: underline;
      text-transform: lowercase;
      text-underline-offset: 5px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  &__link--no-decor {
    text-decoration: none;
    text-underline: none;
    cursor: pointer;
  }
  &__caption {
    .dynamic-fill,
    .style--bare,
    .format--default {
      color: $color-neutral-gray;
      @include typestyle--b1;
    }
  }
  &__product {
    &--title {
      .dynamic-fill,
      .style--bare,
      .format--default {
        @include typestyle--h9;
        color: $color-neutral-dark-gray;
        font-weight: 400;
      }
    }
    &--description,
    &--pricing {
      .dynamic-fill,
      .style--bare,
      .format--default {
        @include typestyle--b1;
        color: $color-neutral-gray;
      }
    }
    &--cta,
    &--quickshop {
      @include link;
      border-bottom: 0;
      padding-top: 10px;
      a.link {
        @include typestyle--b1;
        border-bottom: none;
        color: $color-neutral-dark-gray;
        cursor: pointer;
        text-decoration: underline;
        text-transform: lowercase;
        text-underline-offset: 5px;
        &:first-letter {
          text-transform: uppercase;
        }
        &:hover {
          color: $color-neutral-dark-gray;
        }
      }
    }
  }
}
