.content-block-split-width.column-layout--standard {
  .column {
    &__1 {
      grid-column: 1;
      @include breakpoint($medium-up) {
        grid-column: 1;
      }
    }
    &__2 {
      grid-column: 1;
      @include breakpoint($medium-up) {
        grid-column: 2;
      }
    }
  }
}