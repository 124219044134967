.content-block-split-width__product {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  @include breakpoint($med-small-up) {
    margin-#{$ldirection}: 0;
  }
  .content-block {
    &__line {
      padding: 0;
    }
    &__product {
      &--details {
        padding-top: 0;
        margin-top: 16px;
      }
      &--image {
        &.bg-gradient {
          &__beige {
            background: linear-gradient($color-gradient-setting-beige);
          }
          &__black {
            background: linear-gradient($color-gradient-setting-black);
          }
          &__grey {
            background: linear-gradient($color-gradient-setting-grey);
          }
          &__green {
            background: linear-gradient($color-gradient-setting-green);
          }
          &__light-blue {
            background: linear-gradient($color-gradient-setting-light-blue);
          }
          &__pink {
            background: linear-gradient($color-gradient-setting-pink);
          }
          &__brown {
            background: linear-gradient($color-gradient-setting-brown);
          }
          &__dark-green {
            background: linear-gradient($color-gradient-setting-dark-green);
          }
          &__none {
            background: none;
          }
        }
      }
    }
  }
}